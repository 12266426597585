import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AzureMediaPlayer from './AzureMediaPlayer.jsx';
import NoisyLRS from "./NoisyLRS.jsx";
import TransactionalLRS from "./TransactionalLRS.jsx";
import Divider from '@mui/material/Divider';
import Session from "react-session-api";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
          paddingLeft: 4,
          paddingTop: 3,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
        }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const selected = [];
  const [value, setValue] = React.useState(0);
  const [isNoisyReady, setIsNoisyReady] = React.useState(false);
  const [isTxnReady, setIsTxnReady] = React.useState(false);
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const setReadyNoisy = () => {
    setIsNoisyReady(true);
  };


  const setReadyTxn = () => {
    setIsTxnReady(true);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="inherit">
          
          <Tab label="Video" {...a11yProps(0)} />
          <Tab label="Noisy LRS" {...a11yProps(1)} />
          <Tab label="Transactional LRS" {...a11yProps(2)} />
        </Tabs>
      </Box>
      


      <CustomTabPanel value={value} index={0}>
        <Typography variant="h6" gutterBottom>
          Media Player
        </Typography>
        <AzureMediaPlayer />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Typography variant="h6" gutterBottom>
          Noisy Learning Record Store
        </Typography>
        <NoisyLRS
          setReadyNoisy={setReadyNoisy}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>

        <Typography variant="h6" gutterBottom>
          Transactional Learning Record Store
        </Typography>
        <TransactionalLRS setReadyTxn={setReadyTxn} />
      </CustomTabPanel>

    </Box>
  );
}