import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid'; // Grid version 1
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Session from "react-session-api";
import LoadingButton from '@mui/lab/LoadingButton';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { JsonView, collapseAllNested, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import TransferList from '../Common/TransferList.jsx';
import SelectDropDown from '../Common/SelectDropDown.jsx';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import LoadingIndicator from "../Common/LoadingIndicator.jsx";

function TransactionalLRS(props) {
	let jsx = null;

	const profileDropdown = [
		{ key: 'https://w3id.org/xapi/tla/v/3', value: 'W3id xapi tla V3' },
		{ key: 'https://w3id.org/xapi/tla/v/4', value: 'W3id xapi tla V4' }
	]

	const selected = [];


	let localStatements = Session.get("completedID");
	const [loadImage, setLoadImage] = React.useState(false);
	const [jsonValidity, setJsonValidity] = React.useState(true);
	const [value, setValue] = React.useState(null);
	const [checked, setChecked] = React.useState(true);
	const [transferListData, setTransferListData] = React.useState([]);
	const [selectedList, setSelectedList] = React.useState(selected);
	const [isChildReady, setIsChildReady] = useState(false);
	const [jsonConfig, setJsonConfig] = React.useState({});
	const [profileJson, setProfileJson] = React.useState({});
	const [selectedProfile, setSelectedProfile] = React.useState(selected);
	const [isChildReadyProfile, setIsChildReadyProfile] = useState(false);
	const [containerJson, setContainerJson] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [readyState, setReadyState] = React.useState(false);

	const [currentProfileObj, setCurrentProfileObj] = React.useState(null);

	let currentProfileInternal = "https://poclrspipeprofile.azurewebsites.net/profile_v4_init.json";
	let currentProfileJSONObject;
	let currentProfileJSONText;
	let currentJsonConfigObject;
	let currentJsonConfigText;


	useEffect(() => readConfig(), []);

	useEffect(() => {
		if (!readyState) { setReadyState(true); }

		if (readyState) {
			console.log('Child ready');
			props.setReadyTxn();

		}
	}, [readyState, props, checked]);

	const handleChangeSwitch = (event) => {
		setChecked(event.target.checked);
	};

	let tprofile;

	const readConfig = async () => {
		//Get JsonConfig file from azure storage
		const url = 'https://poclrspipeprofile.azurewebsites.net/profile_init.json';
		const e = await axios.get(url);
		currentJsonConfigObject = e.data;
		setJsonConfig(currentJsonConfigObject);
		currentJsonConfigText = JSON.stringify(e.data);
		// setJsonConfig(currentJsonConfigObject);
		loadConfig();
	}

	const loadConfig = () => {

		setCurrentProfileVariablesAsPerConfig();
		loadProfileAsPerDropDownValue();
	}

	const loadProfileAsPerDropDownValue = () => {
		readProfile(currentProfileInternal);
	}

	const readProfile = async (currentProfileInternal) => {
		return axios.get(currentProfileInternal).then((responseText) => {

			tprofile = JSON.parse(JSON.stringify(responseText.data));
			currentProfileJSONObject = tprofile;
			setProfileJson(currentProfileJSONObject);

			currentProfileJSONText = responseText;
			currentProfileJSONText = responseText.data;
			loadFilter();
		});
	}

	const setCurrentProfileVariablesAsPerConfig = () => {

		let profileurls = currentJsonConfigObject["templates"];
		setSelectedProfile(profileurls[0].inScheme);
		setProfileVariables(profileurls[0]);
	}

	const setProfileVariables = (profl) => {
		let currentProfile = profl;
		if (currentProfile === "https://w3id.org/xapi/tla/v/4") {
			currentProfileInternal = "https://poclrspipeprofile.azurewebsites.net/profile_v4_init.json";
		}
		else if (currentProfile === "https://w3id.org/xapi/tla/v/3") {
			currentProfileInternal = "https://poclrspipeprofile.azurewebsites.net/profile_v3_init.json";
		}
	}


	function loadFilter() {
		let duallistbox_verb = [];
		let templates = currentProfileJSONText["templates"];

		let selectedTemplates = currentJsonConfigObject["templates"];
		let tempProfileSelected = [];
		for (const element of templates) {
			let tempProfileObj = {};
			tempProfileObj["label"] = element.id;
			tempProfileObj["value"] = element.id;
			duallistbox_verb.push(tempProfileObj);
			for (let j of selectedTemplates) {
				if (element.id === j.id) {
					console.log("loadFilter element.id == j : ", j)
					let tempSelectedObj = {};
					tempSelectedObj["label"] = element.id;
					tempSelectedObj["value"] = element.id;
					tempProfileSelected.push(element.id);
				}
			}

		}
		console.log("loadFilter duallistbox_verb : ", duallistbox_verb)
		console.log("loadFilter tempProfileSelected : ", tempProfileSelected)
		setSelectedList(tempProfileSelected);
		setTransferListData(duallistbox_verb);
	}

	const handleSelectedConfigList = (value) => {
		let allSelected = value;

		setSelectedList(value)

		let templates = profileJson.templates;
		let templateIds = [];

		for (const element of templates) {
			for (let j of allSelected) {
				if (element.id === j) {
					console.log("loadFilter element.id == j : ", j)
					templateIds.push(element);
				}
			}
		}
		let configJsonObj = jsonConfig;
		configJsonObj.templates = templateIds;
		console.log(configJsonObj);

		setJsonConfig(configJsonObj);
	}

	const setChildReadyConfig = () => {
		setIsChildReady(true);
	};

	const handleSelectedProfile = (value) => {
		setSelectedProfile(value)
	}

	const setChildReadyProfile = () => {
		setIsChildReadyProfile(true);
	};

	const handleDownloadProfile = (e) => {
		console.log("download event called ", e);
		let textToWrite = document.getElementById("jsonProfile").innerText;
		let textFileAsBlob = new Blob([textToWrite], { type: 'text/plain' });
		let fileNameToSaveAs = "profile.json";

		let downloadLink = document.createElement("a");
		downloadLink.download = fileNameToSaveAs;
		downloadLink.innerHTML = "Download File";
		if (window.webkitURL != null) {
			// Chrome allows the link to be clicked
			// without actually adding it to the DOM.
			downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
		}
		else {
			// Firefox requires the link to be added to the DOM
			// before it can be clicked.
			downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
			downloadLink.onclick = function () {
				document.body.removeChild(downloadLink);
			};
			downloadLink.style.display = "none";
			document.body.appendChild(downloadLink);
		}

		downloadLink.click();
	}

	const handleSaveConfiguration = async (event) => {
		if (!jsonValidity) {
			alert("Profile Invalid and Not saved");
			return;
		}
		let url = 'https://poclrspipeprofile.azurewebsites.net/profile_v4.json';;
		const config = { headers: { 'Content-Type': 'application/json' } };
		if (selectedProfile === "https://w3id.org/xapi/tla/v/3") {
			url = 'https://poclrspipeprofile.azurewebsites.net/profile_v3.json';
		}
		// await axios.post(url, jsonConfig);

		// axios.post('https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/updateConfig', selectedProfile, config)
		// 	.then(response => alert("Profile Updated"));

	}

	const getStatement = async (url, container, loading) => {
		console.log("url ", url, " container ", container, " loading ", loading);
		return axios.get(url);

	}

	const loadStatements = (url, statements, container, filter, loading) => {
		setLoadImage(true);
		let xhrGet = new XMLHttpRequest();
		console.log("localStorage statements:: ", checked, localStatements);
		setLoading(!loading);
		xhrGet.onreadystatechange = function () {
			if (xhrGet.readyState === 4 && xhrGet.status === 200) {
				let resp = xhrGet.response;
				let json = JSON.parse(resp);

				let nestedObject = json.statements;
				let filterVal = value;
				let sessionVal = checked;
				if ((filterVal === null || filterVal === "") && (sessionVal)) {
					console.log("txn if");
					let tempStatements = localStatements;
					if (tempStatements != null) {
						console.log("txn if 1");
						let itemNames = nestedObject.filter(eachObj => ((tempStatements.includes(eachObj.id))));
						setContainerJson({});
						setContainerJson(itemNames);

					}
					else {
						console.log("txn else 1");
						setContainerJson({});
					}
				}
				else if ((filterVal !== null || filterVal !== "") && (sessionVal)) {
					console.log("Noisy else if 1");
					let itemNames = nestedObject.filter(eachObj => Date.parse(eachObj.timestamp) > Date.parse(filterVal));
					setContainerJson({});
					setContainerJson(itemNames);
				}

				else {
					console.log("Noisy else ");
					setContainerJson({});
					setContainerJson(nestedObject);
				}
				setLoading(!loading);
				setLoadImage(false);
			} else {
				console.log("xhr failed");
				setLoadImage(false);
			}

		}
		xhrGet.open('GET', url, true);
		try {
			xhrGet.setRequestHeader("Content-Type", "application/json");
			xhrGet.setRequestHeader("Access-Control-Allow-Origin", "*");
			xhrGet.setRequestHeader('Accept', 'application/json');
			xhrGet.setRequestHeader("Access-Control-Allow-Methods", "GET, POST,PUT");

			xhrGet.send();
		} catch (err) {
			console.log("xhr failed");
			setLoadImage(false);
		}
	}

	const loadLRS = async (url, statementTxN, container, filter, loading) => {
		let filterVal = value;
		let sessionVal = checked;
		console.log("loadLRS localStorage statements:: ", localStatements, checked);
		if ((filterVal === null || filterVal === "") && (sessionVal)) {
			console.log("loadLRS if 1");
			let statements = localStatements;
			if (statements) {
				console.log("loadLRS if 3");
				let stArray = statements.split(",")
				let urlArr = [];
				let tempAPI = "https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/statementNoisy";
				for (const element of stArray) {
					let tempurl = tempAPI + "?statementID=" + element;
					urlArr.push(tempurl);
				}
				const result = await Promise.all(urlArr.map((x) => getStatement(x, container, loading)));
				let response = [];
				for(let data of result) {
					console.log("data", data)
					response.push(data.data);
				}
				setContainerJson(response);
			} 
		} else if ((filterVal !== null || filterVal !== "") && (sessionVal)) {
			console.log("loadLRS else 1");
			const convertDate = (dateString) => new Date(dateString).toISOString();
			let filterDate = convertDate(filterVal);
			let tempurl = "https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/allStatements?since=" + filterDate;
			loadStatements(tempurl, "", container, filter, loading);
		}
		else {
			console.log("loadLRS else 2");
			const convertDate = (dateString) => new Date(dateString).toISOString();
			let filterDate = convertDate(filterVal);
			let tempurl = "https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/allStatements?since=" + filterDate;
			loadStatements(tempurl, "", container, filter, loading);
		}
	}
	const handleSubmitTxnLRS = (url, statements, container, filter, loading) => {
		console.log("called Txn")
		loadLRS(url, statements, container, filter, loading);
	}

	jsx = readyState ? (
		<React.Fragment>
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={1}>
				<Grid item xs={12} >

					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							'& > :not(style)': {
								m: 1,
								width: '100%',
								height: 'auto',
								padding: 3
							},
						}}
					>
						<Paper elevation={3} >
							<div>
								<Accordion defaultExpanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1bh-content"
										id="panel1bh-header"
									>
										<Typography variant="h6">LRSPipe Configuration </Typography>
									</AccordionSummary>
									<AccordionDetails>

										<TransferList transferListData={transferListData}
											alreadySelected={selectedList}
											parentMethodConfig={handleSelectedConfigList}
											setReadyConfig={setChildReadyConfig}
										/>
										<br />
										<Grid sx={{ overflowY: "scroll", maxHeight: "400px" }}
											container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
											<Grid item xs={12}>
												<JsonView id="jsonConfig" data={jsonConfig} shouldExpandNode={collapseAllNested} style={defaultStyles} />
											</Grid>

										</Grid>

									</AccordionDetails>
								</Accordion>
								<br />
								<Accordion >
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2bh-content"
										id="panel2bh-header"
									>
										<Typography variant="h6">Profile </Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Grid
											container direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}>
											<Grid item xs={4}>
												<SelectDropDown name="dropdownProfileSelect" id="dropdownProfileSelect"
													alreadySelected={selectedProfile}
													parentMethodProfile={handleSelectedProfile}
													setReadyProfile={setChildReadyProfile}
													dataInput={profileDropdown}
												/>
											</Grid>

											<Grid item sx={{ marginTop: -3 }}>
												<LoadingButton variant="contained" size="medium" id="profileDownload"
													onClick={(e) => handleDownloadProfile(e)}
												>
													Download Selected Profile
												</LoadingButton></Grid>
										</Grid>
										<Grid sx={{ overflowY: "scroll", maxHeight: "400px" }}
											container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
											<Grid item xs={12}>
												<JsonView id="jsonProfile" data={profileJson} shouldExpandNode={collapseAllNested} style={defaultStyles} />
												<p id="jsonValidity" hidden="hidden">{jsonValidity}</p>
											</Grid>
										</Grid>
									</AccordionDetails>
								</Accordion>
							</div>

							<br />
							<Grid
								container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
								<Grid item xs={2}>
									<LoadingButton variant="contained" size="medium" id="profileDownload"
										onClick={(e) => handleSaveConfiguration(e)}
									>
										Save Configuration
									</LoadingButton>
								</Grid>
							</Grid>
						</Paper>
					</Box>

				</Grid>
			</Grid>
			<br />

			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					'& > :not(style)': {
						m: 1,
						width: '100%',
						height: 'auto',
						padding: 3
					},
				}}
			>
				<Paper elevation={3} >
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={1}>
						<Grid item xs={4}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
									<DateTimePicker id="filterTxn"
										views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
										value={value}
										onChange={(newValue) => setValue(newValue)}
									/>
								</DemoContainer>
							</LocalizationProvider>
						</Grid>

						<Grid sx={{ marginTop: 2, padding: 2 }}>
							<FormControlLabel control={
								<Switch checked={checked}
									onChange={handleChangeSwitch}
									inputProps={{
										'aria-label': 'controlled',
										'id': "currentsessionLRS",
										"name": "currentsessionLRS",
										"value": { checked }
									}}
								/>}
								label="Current session"
							/>
						</Grid>
					</Grid>
					<br />
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={1}>

						<Grid item xs={2}>
							<LoadingButton variant="contained" size="medium"
								onClick={(e) => handleSubmitTxnLRS('https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/allStatements', 'statementsTxn', 'containerTxn', 'filterTxn', 'loadingTxn')}
							>
								Load Statements
							</LoadingButton>
						</Grid>
					</Grid>
					<br />
					{loadImage ? <LoadingIndicator /> :

						<Grid sx={{ overflowY: "scroll", maxHeight: "500px" }}
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={1}>
							<Grid item xs={12}>
								<JsonView id="container" data={containerJson} shouldExpandNode={collapseAllNested} style={defaultStyles} />
							</Grid>
						</Grid>
					}
				</Paper>
			</Box>

		</React.Fragment>
	) : <br />;


	return jsx;

}

export default TransactionalLRS;