import React, { useEffect } from 'react';
import styles from '../assets/CSS/Controls.scss';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FastForwardIcon from '@mui/icons-material/FastForward';

class TransferList extends React.Component {

  state = {
    ready: false,
    selected: null,
    transferListData: null
  }

  constructor(props) {
    
    super(props);
    this.state = {
      transferListData: this.props.transferListData,
      selected: this.props.alreadySelected,
    };

    // if (this.state.transferListData.length !== 0)
      // this.state.transferListData.forEach(element => { element['label'] = element.value });
  }

  onChange = (selected) => {
    this.setState({ selected: selected })

    
    this.props.parentMethodConfig(selected);
    
  };

  async componentDidMount() {
    
    if (!this.state.ready) {
      this.setState({
        transferListData: this.props.transferListData,
        selected: this.props.alreadySelected
      });

      this.setState({ ready: true });
    }   
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.ready !== this.state.ready) {
      if (this.state.ready) {
        this.props.setReadyConfig();
      }
      
    }

    if (prevProps.transferListData.length !== this.props.transferListData.length) {
      this.setState({
        transferListData: this.props.transferListData,
        selected: this.props.alreadySelected
      });
    }
    
  }

  render() {

    return (this.state.ready) ?
      <div className={styles.listBoxConatainer}>
        <DualListBox
          showHeaderLabels
          canFilter
          id="filter"
          options={this.state.transferListData}
          selected={this.state.selected}
          onChange={this.onChange}
          
          icons={{
            moveLeft: <ArrowBackIosIcon/>,
            moveAllLeft: <FastRewindIcon/>,
            moveRight: <ArrowForwardIosIcon/>,
            moveAllRight: <FastForwardIcon/>
        }}
        />
      </div>
      : <div></div>;
  }


}

export default TransferList;
