import Grid from '@mui/material/Grid'; // Grid version 1
import React, { useEffect } from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { JsonView, collapseAllNested, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import Session from "react-session-api";

import LoadingIndicator from "../Common/LoadingIndicator.jsx";

function NoisyLRS(props) {
    let jsx = null;
    let localStatements = Session.get("statements");

    const [loadImage, setLoadImage] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [checked, setChecked] = React.useState(true);
    const [containerJson, setContainerJson] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [readyState, setReadyState] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };



    useEffect(() => {
        if (!readyState) { setReadyState(true); }

        if (readyState) {
            console.log('Child ready');
            props.setReadyNoisy();

        }
    }, [readyState, props, checked]);

    const loadLRS = (url, statements, container, filter, loading) => {
        setLoadImage(true);
        let xhrGet = new XMLHttpRequest();
        console.log("localStorage statements:: ", checked, localStatements);
        setLoading(!loading);
        xhrGet.onreadystatechange = function () {
            if (xhrGet.readyState === 4 && xhrGet.status === 200) {
                let resp = xhrGet.response;
                let json = JSON.parse(resp);

                let nestedObject = json.statements;
                let filterVal = value;
                let sessionVal = checked;
                console.log("Noisy 1 ");
                if ((filterVal === null || filterVal === "") && (sessionVal)) {
                    console.log("Noisy if");
                    let tempStatements = localStatements;
                    if (tempStatements != null) {
                        console.log("Noisy if 1");
                        let itemNames = nestedObject.filter(eachObj => ((tempStatements.includes(eachObj.id))));
                        setContainerJson({});
                        setContainerJson(itemNames);

                    }
                    else {
                        console.log("Noisy else 1");
                        setContainerJson({});
                    }
                }
                else if ((filterVal !== null || filterVal !== "") && (sessionVal)) {
                    console.log("Noisy else if 1");
                    let itemNames = nestedObject.filter(eachObj => Date.parse(eachObj.timestamp) > Date.parse(filterVal));
                    setContainerJson({});
                    setContainerJson(itemNames);
                }

                else {
                    console.log("Noisy else ");
                    setContainerJson({});
                    setContainerJson(nestedObject);
                }
                setLoading(!loading);
                setLoadImage(false);
            }
            else {
                console.log("xhr failed");
                setLoadImage(false);
            }
        }
        xhrGet.open('GET', url, true);
        try {

            xhrGet.setRequestHeader("Content-Type", "application/json");
            xhrGet.setRequestHeader("Access-Control-Allow-Origin", "*");
            xhrGet.setRequestHeader('Accept', 'application/json');
            xhrGet.setRequestHeader("Access-Control-Allow-Methods", "GET, POST,PUT");

            xhrGet.send();
        } catch (err) {
            console.log("xhr failed");
            setLoadImage(false);
        }
    }

    const handleSubmitNoisyLRS = (event, url, statements, container, filter, loading) => {
        loadLRS(url, statements, container, filter, loading);
    }

    jsx = readyState ? (

        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker
                                views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>

                <Grid item sx={{ marginTop: 2, padding: 2 }}>
                    <FormControlLabel control={
                        <Switch checked={checked}
                            id="currentsessionNoisy" name="currentsessionNoisy"
                            onChange={handleChange}
                            inputProps={{
                                'aria-label': 'controlled',
                                "id": "currentsessionNoisy",
                                "name": "currentsessionNoisy",
                                "value": { checked }
                            }} />} label="Current session"
                    />
                </Grid>
            </Grid>
            <br />
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}>

                <Grid item xs={6}>
                    <LoadingButton variant="contained" size="medium"
                        onClick={(e) => handleSubmitNoisyLRS(e, 'https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/allNoisyStatements', 'statements', 'container', 'filter', 'loading')}
                    >
                        Load Statements
                    </LoadingButton>
                </Grid>
            </Grid>

            <br />
            {loadImage ?
                <LoadingIndicator /> :
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}>
                    <Grid item xs={12}>
                        <JsonView data={containerJson} shouldExpandNode={collapseAllNested} style={defaultStyles} />

                    </Grid>
                </Grid>
            }

        </React.Fragment>

    ) : <br />;

    return jsx;

}

export default NoisyLRS;