
import {loadVideoprofile} from '../assets/JS/VideoProfile.js'
import {sendStatement} from '../assets/JS/xapicustomwrapper.js'

const window = global.window

const exitVideo = window.exitVideo;


let actor = {};
let object = {};
let context = {};
let stmt = null;
let volumeChanged = 0;
let seeking = false;
let prevTime = 0.0;
let completed = false;
// let started = false;
let paused = false;
let pauseTime = 0.0;
let videoprofile = loadVideoprofile();

const onPlayerReady = (event) => {
    let message = "player ready";

    console.log(message);
    window.onunload = exitVideo;
}

export const onStateChange = (event, language, length, time, fullscreen, volume, currentSrc, description, attr1, ccEnabled, screenWidth, screenHeight, height, ccLang) => {

    console.log("event:" + event);
    
    switch (event) {
        case "start":
            stmt = initializedVideo(language, length, time, fullscreen, volume, currentSrc, description, attr1, height, screenWidth, screenHeight, ccEnabled, ccLang);
            completed = false;
            // started = true;
            break;
        case "play":
            stmt = playedVideo(language, time, currentSrc, description);
            completed = false;
            break;
        case "seeked":
            stmt = seekedVideo(language, length, time, currentSrc, description, attr1);
            completed = false;
            break;
        case "error":
            stmt = terminatedVideo(language, length, time, currentSrc, description, attr1);
            break;
        case "pause":
            stmt = pausedVideo(language, length, time, currentSrc, description, attr1);
            break;
        case "ended":
            stmt = completedVideo(language, length, time, currentSrc, description, attr1);
            completed = true;
            break;
        default:
            stmt = interactedVideo(language, fullscreen, volume, length, time, currentSrc, description, attr1);
            break;
    }
}


const initializedVideo = (language, length, playbackRate, fullscreen, volume, currentSrc, description, width, height, screenWidth, screenHeight, ccEnabled, ccLang) => {
    let stmt = {};
    stmt.verb = {
        id: videoprofile.verbs.initialized['@id'],
        display: {
            [language]: videoprofile.verbs.initialized.prefLabel.en
        }
    };
        
    let contextExtLength = videoprofile.extensions.length['@id'];
    let contextExtCompletionThreshold = videoprofile.extensions.completion_threshold['@id'];

    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtCCSubtitleEnabled = videoprofile.extensions.cc_subtitle_enabled['@id'];
    let contextExtCCSubtitleLang = videoprofile.extensions.cc_subtitle_lang['@id'];
    let contextExtFrameRate = videoprofile.extensions.frame_rate['@id'];
    let contextExtFullScreen = videoprofile.extensions.full_screen['@id'];
    let contextExtQuality = videoprofile.extensions.quality['@id'];
    let contextExtScreenSize = videoprofile.extensions.screen_size['@id'];
    let contextExtVideoPlaybackSize = videoprofile.extensions.video_playback_size['@id'];
    let contextExtSpeed = videoprofile.extensions.speed['@id'];
    let contextExtTrack = videoprofile.extensions.track['@id'];
    let contextExtUserAgent = videoprofile.extensions.user_agent['@id'];
    let contextExtVolume = videoprofile.extensions.volume['@id'];

    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();
    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },

            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };
    stmt.context =

    {
        platform: window.location.href,
        contextActivities: {
            category: [{
                id: videoprofile.metadata['@id']
            }],
            grouping: [{
                id: videoprofile.metadata['@id']
            }]
        },
        // registration: -1,
        language: language,
        extensions: {
            [contextExtLength]: Number(length),
            // [contextExtCompletionThreshold]: "1",
            // [contextExtSessionId] : "TBD",
            [contextExtCCSubtitleEnabled]: ccEnabled,
            [contextExtCCSubtitleLang]: ccLang,
            // [contextExtFrameRate] : "TBD",
            [contextExtFullScreen]: fullscreen,
            // [contextExtQuality] : "TBD",
            [contextExtScreenSize]: screenWidth + 'x' + screenHeight,
            [contextExtVideoPlaybackSize]: width + 'x' + height,
            [contextExtSpeed]: playbackRate + 'x',
            // [contextExtTrack] : "TBD",
            [contextExtUserAgent]: window.navigator.userAgent,
            [contextExtVolume]: volume
        }
    };
    console.log(stmt);
    sendStatement(buildStatement(stmt), "initialized");
}

const playedVideo = (language, time, currentSrc, description) => {
    let stmt = {};

    stmt.verb = {
        id: videoprofile.verbs.played['@id'],
        display: {
            [language]: videoprofile.verbs.played.prefLabel.en
        }
    };

    // let description = "Event -" + event+ " on Boeing Video in Azure Media
    // Services";
    let resultExtTime = videoprofile.extensions.time['@id'];
    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtSessionIdValue = document.getElementById("sessionId").value;

    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();

    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },
            // description: [description],
            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };
    stmt.context = {
        platform: window.location.href,
        contextActivities: {
            category: [{
                id: videoprofile.metadata['@id']
            }],
            grouping: [{
                id: videoprofile.metadata['@id']
            }]
        },
        // registration: -1,
        language: language,
        extensions: {
            [contextExtSessionId]: contextExtSessionIdValue
        }
    };
    stmt.result = {
        extensions: {
            [resultExtTime]: Number(time)
        }
        // duration: -1
    };
    sendStatement(buildStatement(stmt), "played");


}

const pausedVideo = (language, length, time, currentSrc, description, playedSegments) => {
    let stmt = {};
    let completionThreshold = (length - time) / length;
    let progress = parseFloat(time / length).toFixed(3);

    stmt.verb = {
        id: videoprofile.verbs.paused['@id'],
        display: {
            [language]: videoprofile.verbs.paused.prefLabel.en
        }
    };

    let contextExtLength = videoprofile.extensions.length['@id'];
    let contextExtCompletionThreshold = videoprofile.extensions.completion_threshold['@id'];
    let resultExtTime = videoprofile.extensions.time['@id'];
    let resultExtProgress = videoprofile.extensions.progress['@id'];
    let resultExtPlayedSegments = videoprofile.extensions.played_segments['@id'];

    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtSessionIdValue = document.getElementById("sessionId").value;
    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();

    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },
            // description: [description],
            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };
    stmt.context = {
        platform: window.location.href,
        contextActivities: {
            category: [{
                id: videoprofile.metadata['@id']
            }],
            grouping: [{
                id: videoprofile.metadata['@id']
            }]
        },
        // registration: -1,
        language: language,
        extensions: {
            [contextExtLength]: Number(length),
            // [contextExtCompletionThreshold] :
            // parseFloat(completionThreshold).toFixed(3),
            [contextExtSessionId]: contextExtSessionIdValue
        }
    };
    stmt.result = {
        extensions: {
            [resultExtTime]: Number(time),
            [resultExtProgress]: Number(progress),
            [resultExtPlayedSegments]: playedSegments
        }
        // duration: -1
    };
    // console.log(stmt);
    sendStatement(buildStatement(stmt), "paused");
}

const seekedVideo = (language, length, time, currentSrc, description, attr1) => {
    let stmt = {};
    stmt.verb = {
        id: videoprofile.verbs.seeked['@id'],
        display: {
            [language]: videoprofile.verbs.seeked.prefLabel.en
        }
    };

    // let description = "Event -" + event+ " on Boeing Video in Azure
    // Media Services";
    let resultExtTimeFrom = videoprofile.extensions.time_from['@id'];
    let resultExtTimeTo = videoprofile.extensions.time_to['@id'];

    let resultExtProgress = videoprofile.extensions.progress['@id'];
    let resultExtPlayedSegments = videoprofile.extensions.played_segments['@id'];

    let contextExtLength = videoprofile.extensions.length['@id'];
    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtSessionIdValue = document.getElementById("sessionId").value;
    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();

    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },
            // description: [description],
            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };
    stmt.context = {
        platform: window.location.href,
        // registration: -1,
        contextActivities: {
            category: [{
                id: videoprofile.metadata['@id']
            }],
            grouping: [{
                id: videoprofile.metadata['@id']
            }]
        },
        language: language,
        extensions: {
            [contextExtLength]: Number(length),
            [contextExtSessionId]: contextExtSessionIdValue
        }
    };
    stmt.result = {
        extensions: {
            [resultExtTimeFrom]: Number(attr1),
            [resultExtTimeTo]: Number(time)
            // [resultExtProgress] : "TBD",
            // [resultExtPlayedSegments] : "TBD"
        },
        // completion: false,
        // duration: -1
    };
    sendStatement(buildStatement(stmt), "seeked");
}

const interactedVideo = (language, fullScreen, volume, length, time, currentSrc, description, ccEnabled) => {
    let stmt = {};
    // alert(language);
    stmt.verb = {
        id: videoprofile.verbs.interacted['@id'],
        display: {
            [language]: videoprofile.verbs.interacted.prefLabel.en
        }
    };

    // let description = "Event -" + event+ " on Boeing Video in Azure Media
    // Services";
    let resultExtTime = videoprofile.extensions.time['@id'];

    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtCCSubtitleEnabled = videoprofile.extensions.cc_subtitle_enabled['@id'];
    let contextExtCCSubtitleLang = videoprofile.extensions.cc_subtitle_lang['@id'];
    let contextExtFrameRate = videoprofile.extensions.frame_rate['@id'];
    let contextExtFullScreen = videoprofile.extensions.full_screen['@id'];
    let contextExtQuality = videoprofile.extensions.quality['@id'];
    let contextExtVideoPlaybackSize = videoprofile.extensions.video_playback_size['@id'];
    let contextExtSpeed = videoprofile.extensions.speed['@id'];
    let contextExtTrack = videoprofile.extensions.track['@id'];
    let contextExtVolume = videoprofile.extensions.volume['@id'];
    let contextExtSessionIdValue = document.getElementById("sessionId").value;
    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();

    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },
            // description: [description],
            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };

    if (fullScreen != "NA") {

        stmt.context = {
            contextActivities: {
                category: [{
                    id: videoprofile.metadata['@id']
                }],
                grouping: [{
                    id: videoprofile.metadata['@id']
                }]
            },
            extensions: {
                [contextExtSessionId]: contextExtSessionIdValue,
                [contextExtFullScreen]: fullScreen
            }
        };
    } else if (volume != "NA") {

        stmt.context = {
            contextActivities: {
                category: [{
                    id: videoprofile.metadata['@id']
                }],
                grouping: [{
                    id: videoprofile.metadata['@id']
                }]
            },
            extensions: {
                [contextExtSessionId]: contextExtSessionIdValue,
                [contextExtVolume]: Number(volume)
            }
        };
    } else {

        stmt.context = {
            contextActivities: {
                category: [{
                    id: videoprofile.metadata['@id']
                }],
                grouping: [{
                    id: videoprofile.metadata['@id']
                }]
            },
            extensions: {
                [contextExtSessionId]: contextExtSessionIdValue,
                [contextExtCCSubtitleEnabled]: ccEnabled
                // [contextExtCCSubtitleLang] : "TBD",
                // [contextExtFrameRate] : "TBD",
                // [contextExtQuality] : "TBD",
                // [contextExtVideoPlaybackSize] : "TBD",
                // [contextExtSpeed] : "TBD",
                // [contextExtTrack] : "TBD",
                // [contextExtVolume] : "TBD"
            }
        };
    }

    if (completed) {
        stmt.result = {
            extensions: {
                [resultExtTime]: Number(time)
            },
            completion: true
            // duration: -1
        };
        // alert(stmt);
    } else {
        stmt.result = {
            extensions: {
                [resultExtTime]: Number(time)
            }
        };
    }

    // console.log(stmt);
    sendStatement(buildStatement(stmt), "interacted");
}

const completedVideo = (language, length, time, currentSrc, description, playedSegments) => {
    // console.log("playedSegments:" + playedSegments);
    let stmt = {};
    stmt.verb = {
        id: videoprofile.verbs.completed['@id'],
        display: {
            [language]: videoprofile.verbs.completed.prefLabel.en
        }
    };

    let progress = parseFloat(time / length).toFixed(3);
    // let description = "Event -" + event+ " on Boeing Video in Azure
    // Media Services";
    let contextExtLength = videoprofile.extensions.length['@id'];
    let contextExtCompletionThreshold = videoprofile.extensions.completion_threshold['@id'];

    let resultExtTime = videoprofile.extensions.time['@id'];
    let resultExtProgress = videoprofile.extensions.progress['@id'];
    let resultExtPlayedSegments = videoprofile.extensions.played_segments['@id'];

    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtSessionIdValue = document.getElementById("sessionId").value;
    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();
    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },
            // description: [description],
            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };
    stmt.context =
        stmt.context = {
            platform: window.location.href,
            contextActivities: {
                category: [{
                    id: videoprofile.metadata['@id']
                }]
            },
            // registration: -1,
            language: language,
            extensions: {
                [contextExtLength]: Number(length),
                // [contextExtCompletionThreshold] : 0,
                [contextExtSessionId]: contextExtSessionIdValue
            }
        };
    stmt.result = {
        extensions: {
            [resultExtTime]: Number(time),
            [resultExtProgress]: Number(progress),
            [resultExtPlayedSegments]: playedSegments
        },
        completion: true,
        // duration: length
    };
    // alert(stmt);
    // console.log(stmt);
    sendStatement(buildStatement(stmt), "completed");
}

const getPlatform = () => {
    let navUserAgent = navigator.userAgent;
    let browserName = navigator.appName;
    let browserVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let tempNameOffset, tempVersionOffset, tempVersion;


    if ((tempVersionOffset = navUserAgent.indexOf("Opera")) != -1) {
        browserName = "Opera";
        browserVersion = navUserAgent.substring(tempVersionOffset + 6);
        if ((tempVersionOffset = navUserAgent.indexOf("Version")) != -1)
            browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Edg")) != -1) {
        browserName = "Microsoft Edge";
        browserVersion = navUserAgent.substring(tempVersionOffset + 4);
    } else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        browserVersion = navUserAgent.substring(tempVersionOffset + 5);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) != -1) {
        browserName = "Safari";
        browserVersion = navUserAgent.substring(tempVersionOffset + 7);
        if ((tempVersionOffset = navUserAgent.indexOf("Version")) != -1)
            browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if ((tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) < (tempVersionOffset = navUserAgent.lastIndexOf('/'))) {
        browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
        browserVersion = navUserAgent.substring(tempVersionOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }

    // trim version
    if ((tempVersion = browserVersion.indexOf(";")) != -1)
        browserVersion = browserVersion.substring(0, tempVersion);
    if ((tempVersion = browserVersion.indexOf(" ")) != -1)
        browserVersion = browserVersion.substring(0, tempVersion);

    return browserName + "-" + browserVersion;
}

const buildStatement = (statement) => {
    let tempStmt = null;
    if (statement) {
        let tempStmt = statement;
        tempStmt.actor = actor;
        return tempStmt;
        // stmt.object = object;
        // stmt.context = context;
    }
}

const terminatedVideo = (language, length, time, currentSrc, description, playedSegments) => {
    // console.log("playedSegments:" + playedSegments);
    let progress = parseFloat(time / length).toFixed(3);

    let stmt = {};
    stmt.verb = {
        id: videoprofile.verbs.terminated['@id'],
        display: {
            [language]: videoprofile.verbs.terminated.prefLabel.en
        }
    };

    // let description = "Event -" + event+ " on Boeing Video in Azure
    // Media Services";
    let contextExtLength = videoprofile.extensions.length['@id'];
    let contextExtCompletionThreshold = videoprofile.extensions.completion_threshold['@id'];

    let resultExtTime = videoprofile.extensions.time['@id'];
    let resultExtProgress = videoprofile.extensions.progress['@id'];
    let resultExtPlayedSegments = videoprofile.extensions.played_segments['@id'];

    let contextExtSessionId = videoprofile.extensions.session_id['@id'];
    let contextExtSessionIdValue = document.getElementById("sessionId").value;
    let fileName = currentSrc.substring(currentSrc.lastIndexOf('/') + 1, currentSrc.lastIndexOf('.')).trim();

    stmt.object = {
        definition: {
            name: {
                [language]: fileName
            },
            description: {
                [language]: description
            },
            // description: [description],
            type: videoprofile.activity_types.video['@id']
        },
        id: currentSrc,
        objectType: videoprofile.activity_types.video['@type']
    };
    stmt.context =
        stmt.context = {
            platform: window.location.href,
            // registration: -1,
            contextActivities: {
                category: [{
                    id: videoprofile.metadata['@id']
                }]
            },
            language: language,
            extensions: {
                [contextExtLength]: Number(length),
                // [contextExtCompletionThreshold] : "TBD",
                [contextExtSessionId]: contextExtSessionIdValue
            }
        };

    if (completed) {
        stmt.result = {
            extensions: {
                [resultExtTime]: Number(time),
                [resultExtProgress]: Number(progress),
                [resultExtPlayedSegments]: playedSegments
            },
            completion: true
            // duration: -1
        };
    } else {
        stmt.result = {
            extensions: {
                [resultExtTime]: Number(time),
                [resultExtProgress]: Number(progress),
                [resultExtPlayedSegments]: playedSegments
            }
            // duration: -1
        };
    }

    
}


