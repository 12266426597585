import React, { Fragment } from "react";

import styles from "./app.styles.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AzureMediaPlayer from "./Components/AzureMediaPlayer.jsx";
import BasicTabs from "./Components/CustomTabPanel.jsx";
import dayjs from "dayjs";
import Session from "react-session-api";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoadingIndicator from './Common/LoadingIndicator.jsx';

import AirSharpIcon from '@mui/icons-material/AirSharp';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import logo from './assets/Images/boeing_logo_1.png';
import Grid from "@mui/material/Grid";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function App() {

  const currentDate = dayjs();
  const formattedDate = currentDate.format("YYYY-MM-DD HH:mm:ss a");
  Session.set("statements", "");
  Session.set("loggedintime", formattedDate);
  Session.set("displayStatements", []);
  Session.set("ampTableRows", []);
  Session.set("courseID", "");
  Session.set("courseTitle", "");
  Session.set("actor", "");
  Session.set("completedID", "");
  Session.set("actorCompleteCourse", "");


  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar color="transparent" sx={{ backgroundColor: "#ffffff" }} position="fixed" open={open}>
            <Container maxWidth="false" >
              <Toolbar disableGutters>
                <Grid
                  // container
                  // direction="row"
                  justifyContent="align-left"
                  alignItems="left"
                >
                  {/* <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                  >
                    <MenuIcon />
                  </IconButton> */}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <img src={logo} alt="Logo" />

                  <Typography
                    variant="h4"
                    noWrap
                    component="a"
                    href="#app-bar-with-responsive-menu"
                    sx={{
                      fontFamily: "Arial, Helvetica, sans-serif",
                      boxSizing: "border-box",
                      mr: 2,
                      display: { xs: 'none', md: 'flex' },
                      fontWeight: 700,
                      // letterSpacing: '.15rem',
                      color: ' #0033A1',
                      textDecoration: 'none',
                    }}
                  >
                    xAPI Example Demo Application
                  </Typography>

                </Grid>

              </Toolbar>
            </Container>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>

              <ListItem key='Event Driven TLA' disablePadding>
                <Link underline="none"  to="tla" ><ListItemButton>
                  <ListItemIcon>
                    <AirSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary='Event Driven TLA' />
                </ListItemButton> </Link>
              </ListItem>
              <ListItem key='Experience Index' disablePadding>
                <Link underline="none"   to="expIndex"><ListItemButton >
                  <ListItemIcon>
                    <ListSharpIcon />
                  </ListItemIcon>
                  <ListItemText primary='Experience Index PoC' />
                </ListItemButton> </Link>
              </ListItem>


            </List>
            <Divider />
            <ListItem key='Home' disablePadding>
              <Link underline="none"   to="/"><ListItemButton >
                <ListItemIcon>
                  <HomeTwoToneIcon />
                </ListItemIcon>
                <ListItemText primary='Back to Home' />
              </ListItemButton> </Link>
            </ListItem>
          </Drawer>
          <Main open={open} >
            <DrawerHeader />
            <Container sx={{
              flexGrow: 1, overflow: "auto", marginBottom: 4, marginTop: 4, minHeight:"100%"
            }} maxWidth="100%">

              <Switch>
                <Route path="/load">
                  <LoadingIndicator />
                </Route>
                <Route path="/main">
                  <BasicTabs />
                </Route>
                <Route path="/media">
                  <AzureMediaPlayer />
                </Route>
                <Route path="/tabs">
                  <BasicTabs />
                </Route>
                
                <Route path="/">
                  <BasicTabs />
                </Route>
              </Switch>
            </Container>
          </Main>
        </Box>
      </Router>
      <footer >
        <div className={styles.wrapper}>
          <Typography variant="body1">
            Copyright &copy; {new Date().getFullYear()} Boeing | All rights reserved
          </Typography>
        </div>
      </footer>
    </Fragment >
  );
}
export default App;
