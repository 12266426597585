import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

class SelectDropDown extends React.Component {

    state = {
        ready: false,
        selectedValue: null,
        selectId: null,
        selectLabel: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: this.props.alreadySelected,
            inputData: this.props.dataInput,
            selectId: this.props.selectedId,
            selectLabel: this.props.selectedLabel
        };

    }

    async componentDidMount() {
        if (!this.state.ready) {

            this.setState({
                selectedValue: this.props.alreadySelected,
                selectId: this.props.selectedId,
                selectLabel: this.props.selectedLabel
                
            });

            this.setState({ ready: true });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.ready !== this.state.ready) {
            if (this.state.ready) {
                console.log('Child state ready');
                this.props.setReadyProfile();
            }
        }

        if (prevProps.dataInput !== this.props.dataInput) {
            if (this.state.ready) {
                console.log('Child props ready');
                this.setState({  inputData: this.props.dataInput, });
            }
        }
    }

    handleChange = (event) => {
        this.setState({ selectedValue: event.target.value })
        this.props.parentMethodProfile(event.target.value);
    };




    render() {

        return (this.state.ready) ?
            <div>
                <FormControl sx={{margin: '1em 0em 1em 0em', minWidth: 260 }}>
                    <InputLabel id={this.state.selectId} >{this.state.selectLabel}</InputLabel>
                    <Select
                        labelId={this.state.selectId}
                        id={this.state.selectId}
                        name={this.state.selectId}
                        value={this.state.selectedValue || "1"}
                        label={this.state.selectLabel}
                        onChange={this.handleChange}
                    >
                        {this.state.inputData.map((ele) => {
                            return (
                                <MenuItem value={ele.value}>{ele.key}</MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>Select {this.state.selectLabel} from the list</FormHelperText>
                </FormControl>
            </div>
            : <div></div>;
    }
}
export default SelectDropDown;