import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Session from "react-session-api";
import CustomTable from "../Common/CustomTable.jsx"
import MediaPlayer from "./MediaPlayer.jsx"

const columns = [
  // { id: 'statementID', label: 'Statement ID', minWidth: 170 },
  { id: 'actor', label: 'Actor', minWidth: 100 },
  { id: 'verb', label: 'Verb', minWidth: 100 },
  { id: 'objectID', label: 'Object ID', minWidth: 170 },
  { id: 'objectName', label: 'Object (activity) Name', minWidth: 170 },
  { id: 'objectType', label: 'Object (activity) Type', minWidth: 170 },
  { id: 'objectDesc', label: 'Description of the object/activity', minWidth: 170 },
  { id: 'activityTypeIRI', label: 'Activity Type IRI', minWidth: 170 },
  { id: 'contextObj', label: 'Context', minWidth: 170, format:'json' },
];

function AzureMediaPlayer(props) {
  const [isChildReadyTable, setIsChildReadyTable] = useState(false);
  const [isChildReadyVideo, setIsChildReadyVideo] = useState(false);
  
  let jsx = null;

  useEffect(() => {
  }, [])


  const setChildReadyTable = () => {
    setIsChildReadyTable(true);
    console.log("did setChildReadyTable Method :: ", isChildReadyTable);
  };
  const setChildReadyVideo = () => {
    setIsChildReadyVideo(true);
    console.log("did setIsChildReadyVideo Method :: ", isChildReadyVideo);
  };

  jsx = (
    <React.Fragment>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0.5} >
        <Grid item xs={5} >
          <MediaPlayer 
            setChildReadyVideo={setChildReadyVideo}
          />
        </Grid>
        <Grid item xs={7} >
          <div style={{ width: "96%", height: "96%" }} >
            <Grid
              sx={{ overflowY: "auto", maxHeight: "350px", minHeight: "350px" }}
              container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}
              border={1} borderColor="#0000001f" borderRadius={5}>
              <pre id="message" style={{ backgroundColor: "white", border: "0px" }} ></pre>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <br />
      <Grid sx={{ maxHeight: "1000px" }}
        container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} >
        <CustomTable
          columns={columns}
          rows={Session.get("ampTableRows")}
          setReadyTable={setChildReadyTable}
          component="amp"
        />
      </Grid>
      <TextField
        id="sessionId"
        label="Standard"
        letiant="standard"
        sx={{ display: "none" }}
      />
    </React.Fragment>
  );

  return jsx;
}

export default AzureMediaPlayer;
