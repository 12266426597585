const window = global.window;
export const loadVideoprofile = () => {
  return window.ADL.videoprofile;
};
(function (ADL) {
  ADL.videoprofile = {
    metadata: {
      "@context": "http://xapi.vocab.pub/vocabulary/context.jsonld",
      "@id": "https://w3id.org/xapi/video",
      "@type": "ConceptScheme",
      prefLabel: {
        en: "Video Profile Vocabulary",
      },
      created: {
        en: "2016-6-09",
      },
      modified: {
        en: "2016-9-23",
      },
      editorialNote: {
        en:
          "The video vocabulary of the xAPI was created to identify and standardize common video interactions that can be tracked.",
      },
      wasGeneratedBy: {
        name: {
          en: "xAPI Video Community of Practice",
        },
      },
    },
    activity_types: {
      video: {
        "@id": "https://w3id.org/xapi/video/activity-type/video",
        "@type": "Activity",
        definition: {
          en:
            "A recording of both the visual and audible components made available on a display screen.",
        },
        prefLabel: {
          en: "video",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "http://wordnet-rdf.princeton.edu/wn31/104541422-n",
        },
      },
    },
    extensions: {
      time: {
        "@id": "https://w3id.org/xapi/video/extensions/time",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#float",
        },
        definition: {
          en: "Used to express the time into the video.",
        },
        prefLabel: {
          en: "time",
        },
        scopeNote: {
          en:
            "The LRP MUST assign time value to statements with 'played', 'paused', 'terminated', 'interacted' and 'completed' verbs.Float Value with maximum 3 decimals.",
        },
      },
      time_from: {
        "@id": "https://w3id.org/xapi/video/extensions/time-from",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#float",
        },
        definition: {
          en:
            "Used to identify the point in time the actor changed from in a media object during a seek operation.",
        },
        prefLabel: {
          en: "time-from",
        },
        scopeNote: {
          en:
            "The LRP MUST assign time-from value to statements with 'seeked' verbs only.Float Value with maximum 3 decimals.",
        },
      },
      time_to: {
        "@id": "https://w3id.org/xapi/video/extensions/time-to",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#float",
        },
        definition: {
          en:
            "Used to identify the point in time the actor changed to in a media object during a seek operation.",
        },
        prefLabel: {
          en: "time-to",
        },
        scopeNote: {
          en:
            "The LRP MUST assign time-to value to statements with 'seeked' verbs only.Float Value with maximum 3 decimals.",
        },
      },
      progress: {
        "@id": "https://w3id.org/xapi/video/extensions/progress",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "https://w3id.org/xapi/video/extensions/progressRange",
        },
        definition: {
          en:
            "Used to expresses the percentage of media consumed by the actor.",
        },
        prefLabel: {
          en: "progress",
        },
        scopeNote: {
          en:
            "The LRP MUST assign progress value to statements with 'paused', 'terminated' and 'completed' verbs.The LRP MAY assign progress value to other statements.Float value between 0 and 1, with a maximum of 3 decimals.",
        },
      },
      progressRange: {
        "@id": "https://w3id.org/xapi/video/extensions/progressRange",
        "@type": ["Restriction", "Datatype"],
        onDatatype: {
          "@id": "http://www.w3.org/2001/XMLSchema#integer",
        },
        onProperty: {
          "@id": "https://w3id.org/xapi/video/extensions/progress",
        },
        withRestrictions: {
          "@list": [
            {
              "@id": "https://w3id.org/xapi/video/extensions/minInclusive",
            },
            {
              "@id": "https://w3id.org/xapi/video/extensions/maxInclusive",
            },
          ],
        },
      },
      minInclusive: {
        "@id": "https://w3id.org/xapi/video/extensions/minInclusive",
        "@type": "DatatypeProperty",
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#integer",
        },
        minInclusive: "0.0",
      },
      maxInclusive: {
        "@id": "https://w3id.org/xapi/video/extensions/maxInclusive",
        "@type": "DatatypeProperty",
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#integer",
        },
        maxInclusive: "1.0",
      },
      played_segments: {
        "@id": "https://w3id.org/xapi/video/extensions/played-segments",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#float",
        },
        definition: {
          en:
            "Played segments data shows parts of the video the actor watched during current registration in chronological order.",
        },
        prefLabel: {
          en: "played-segments",
        },
        scopeNote: {
          en:
            "The LRP MUST assign played-segments value to statements with 'paused', 'terminated' and 'completed' verbs.",
        },
      },
      //Context
      session_id: {
        "@id": "https://w3id.org/xapi/video/extensions/session-id",
        "@type": "Datatype",
        onDatatype: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        withRestrictions: {
          "@list": [
            {
              "@id": "http://www.w3.org/2001/XMLSchema#pattern",
            },
            "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[8-9a-bA-B][0-9a-fA-F]{3}-[0-9a-fA-F]{12}",
          ],
        },
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        definition: {
          en:
            "Used to tell which session the statement is part of. A session starts with the initiated statement and ends with terminated or abandoned statement.",
        },
        prefLabel: {
          en: "session-id",
        },
        scopeNote: {
          en:
            "Value of session id has to be the UUID statement id of the initiated statement.",
        },
      },
      cc_subtitle_enabled: {
        "@id": "https://w3id.org/xapi/video/extensions/cc-enabled",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#boolean",
        },
        definition: {
          en:
            "Used to expresses whether subtitle or closed captioning is enabled. ",
        },
        prefLabel: {
          en: "cc-subtitle-enabled",
        },
        scopeNote: {
          en: "Value is boolean as in true or false.",
        },
      },
      cc_subtitle_lang: {
        "@id": "https://w3id.org/xapi/video/extensions/cc-subtitle-lang",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#language",
        },
        definition: {
          en: "Used to express the language of subtitle or closed captioning.",
        },
        prefLabel: {
          en: "cc-subtitle-lang",
        },
        scopeNote: {
          en:
            "Used only when cc-subtitle-enabled is true. The lexical and value spaces of language are the set of language codes defined by RFC 1766.",
        },
      },
      frame_rate: {
        "@id": "https://w3id.org/xapi/video/extensions/frame-rate",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#decimal",
        },
        definition: {
          en:
            "Used to express the frame rate or frames per second of a video (or average rate of frames per second in the case of variable frame-rate). Represented as a ratio of time base over frame duration, such as 30000/1001 or as a decimal, such as 29.970.",
        },
        prefLabel: {
          en: "frame-rate",
        },
      },
      full_screen: {
        "@id": "https://w3id.org/xapi/video/extensions/full-screen",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#boolean",
        },
        definition: {
          en: "Used to expresses that the video is played in full screen mode.",
        },
        prefLabel: {
          en: "full-screen",
        },
        scopeNote: {
          en: "Value is boolean true or false.",
        },
      },
      quality: {
        "@id": "https://w3id.org/xapi/video/extensions/quality",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        definition: {
          en: "Used to express the video resolution or quality.",
        },
        prefLabel: {
          en: "quality",
        },
        scopeNote: {
          en:
            "Value is a string. e.g. 360, 480, 720, 1080, 2160, sd, hd, 4k, 8k etc.",
        },
      },
      screen_size: {
        "@id": "https://w3id.org/xapi/video/extensions/screen-size",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        definition: {
          en:
            "Used to express the device playback screen size or the maximum available screensize for Video playback.",
        },
        prefLabel: {
          en: "screen-size",
        },
        scopeNote: {
          en: "Value WxH in pixels (e.g., 1080x960, 640x480, 800x600).",
        },
      },
      video_playback_size: {
        "@id": "https://w3id.org/xapi/video/extensions/video-playback-size",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        definition: {
          en:
            "Used to identify the size in Width x Height of the video as viewed by the user.",
        },
        prefLabel: {
          en: "video-playback-size",
        },
        scopeNote: {
          en:
            "Value MUST be in WxH format in pixels. e.g. 1080x960, 640x480, 800x600",
        },
      },
      speed: {
        "@id": "https://w3id.org/xapi/video/extensions/speed",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        definition: {
          en: "Used to express the play-back speed (e.g., 1x,2x,0,-1x,-2x).",
        },
        prefLabel: {
          en: "speed",
        },
        scopeNote: {
          en:
            "Float Value with an ‘x’ Negative = Rewind Positive = Fast Forward",
        },
      },
      track: {
        "@id": "https://w3id.org/xapi/video/extensions/track",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        definition: {
          en: "Used to identify the name of the audio track in a media object.",
        },
        prefLabel: {
          en: "track",
        },
      },
      user_agent: {
        "@id": "https://w3id.org/xapi/video/extensions/user-agent",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "http://www.w3.org/2001/XMLSchema#string",
        },
        definition: {
          en:
            "Used to identify the User Agent string of the browser, if the video is launched in browser.",
        },
        prefLabel: {
          en: "user-agent",
        },
      },
      volume: {
        "@id": "https://w3id.org/xapi/video/extensions/volume",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "https://w3id.org/xapi/ontology#Extension",
        },
        range: {
          "@id": "https://w3id.org/xapi/video/extensions/volumeRange",
        },
        definition: {
          en:
            "Used to identify the loudness of sound specified for a media object.",
        },
        prefLabel: {
          en: "volume",
        },
        scopeNote: {
          en:
            "Volume Level (0 to 1). Float Value. Minimum 0 = Mute. Maximum 1 = 100% Volume",
        },
      },
      volumeRange: {
        "@id": "https://w3id.org/xapi/video/extensions/volumeRange",
        "@type": ["Restriction", "Datatype"],
        onDatatype: {
          "@id": "http://www.w3.org/2001/XMLSchema#integer",
        },
        onProperty: {
          "@id": "https://w3id.org/xapi/video/extensions/volume",
        },
        withRestrictions: {
          "@list": [
            {
              "@id": "https://w3id.org/xapi/video/extensions/minInclusive",
            },
            {
              "@id": "https://w3id.org/xapi/video/extensions/maxInclusive",
            },
          ],
        },
      },
      length: {
        "@id": "https://w3id.org/xapi/video/extensions/length",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "",
        },
        range: {
          "@id": "",
        },
        definition: {
          en: "",
        },
        prefLabel: {
          en: "length",
        },
        scopeNote: {
          en: "",
        },
      },
      completion_threshold: {
        "@id": "https://w3id.org/xapi/video/extensions/completion-threshold",
        "@type": "DatatypeProperty",
        domain: {
          "@id": "",
        },
        range: {
          "@id": "",
        },
        definition: {
          en: "",
        },
        prefLabel: {
          en: "length",
        },
        scopeNote: {
          en: "",
        },
      },
    },
    verbs: {
      initialized: {
        "@id": "http://adlnet.gov/expapi/verbs/initialized",
        "@type": "Verb",
        closeMatch: {
          "@id": "",
        },
        definition: {
          en:
            "An 'Initialized' statement is used by the LRP to indicate that the video has been fully initialized or launched.",
        },
        prefLabel: {
          en: "initialized",
        },
        scopeNote: {
          en:
            "The LRP MUST use 'Initialized' in the first statement in the video session. The LRP MUST NOT issue multiple statements with 'Initialized' for the same video session.",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "",
        },
      },
      played: {
        "@id": "https://w3id.org/xapi/video/verbs/played",
        "@type": "Verb",
        closeMatch: {
          "@id": "http://activitystrea.ms/schema/1.0/play",
        },
        definition: {
          en:
            "Indicates that the actor started experiencing the recorded media object.",
        },
        prefLabel: {
          en: "played",
        },
        scopeNote: {
          en:
            "Used when the actor generally played a video or clicked the play button.",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "http://wordnet-rdf.princeton.edu/wn31/201721028-v",
        },
      },
      paused: {
        "@id": "https://w3id.org/xapi/video/verbs/paused",
        "@type": "Verb",
        closeMatch: {
          "@id": "",
        },
        definition: {
          en:
            "Indicates that the actor temporary or permanently stopped experiencing the recorded media object.",
        },
        prefLabel: {
          en: "paused",
        },
        scopeNote: {
          en:
            "The LRP MUST use 'Paused' when the media is stopped. A paused statement MUST be sent before a terminated statement if not already sent.",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "",
        },
      },
      seeked: {
        "@id": "https://w3id.org/xapi/video/verbs/seeked",
        "@type": "Verb",
        definition: {
          en:
            "Indicates the actor changed the progress towards a specific point.",
        },
        prefLabel: {
          en: "seeked",
        },
        scopeNote: {
          en:
            "The LRP MUST use 'Seeked' when the Actor moves the progress bar forward or backward to a specific time in video.",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "http://wordnet-rdf.princeton.edu/wn31/201843112-v",
        },
      },
      interacted: {
        "@id": "http://adlnet.gov/expapi/verbs/interacted",
        "@type": "Verb",
        closeMatch: {
          "@id": "",
        },
        definition: {
          en:
            "Used to express that the actor interacted with the player (except play, pause, seek). e.g. mute, unmute, change resolution, change player size, etc.",
        },
        prefLabel: {
          en: "interacted",
        },
        scopeNote: {
          en: "Optional",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "",
        },
      },
      completed: {
        "@id": "http://adlnet.gov/expapi/verbs/completed",
        "@type": "Verb",
        closeMatch: {
          "@id": "",
        },
        definition: {
          en:
            "Used to express that the actor completed a video by watching all parts of the video at least once.",
        },
        prefLabel: {
          en: "completed",
        },
        scopeNote: {
          en:
            "Used to express that the actor completed a video by watching all parts of the video at least once. The progress and heatmap result extension can be used to calculate the duration and completion of video.",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "",
        },
      },
      terminated: {
        "@id": "http://adlnet.gov/expapi/verbs/terminated",
        "@type": "Verb",
        closeMatch: {
          "@id": "",
        },
        definition: {
          en: "Used to express that the actor ended a video.",
        },
        prefLabel: {
          en: "terminated",
        },
        scopeNote: {
          en: "Optional",
        },
        closelyRelatedNaturalLanguageTerm: {
          "@id": "",
        },
      },
    },
  };
})((window.ADL = window.ADL || {}));
