import React, { useCallback, useEffect, useState, useRef } from 'react';
import oceanVideo from '../assets/videos/oceanVideo_648x270_AACAudio_430.mp4'
import { onStateChange } from "./XAPIStatements.js";
import Session from "react-session-api";

export default function MediaPlayer(props) {
    const videoRef = useRef(null);

    const [ready, setReady] = useState(false);
    const [language, setLanguage] = useState("en");
    // const [didComplete, setDidComplete] = useState(false);

    useEffect(() => {
        if (!ready) {
            setReady(true);
            props.setChildReadyVideo();
        }
        if (ready)
            ['loadeddata', 'play', 'pause', 'seeked', 'seeking', 'volumechange', 'ended'].forEach(event =>
                videoRef.current.addEventListener(event, handleEvent)
            )

        console.log("videoRef ", videoRef)
        return () => {
            ['loadeddata', 'play', 'pause', 'seeked', 'seeking', 'volumechange', 'ended'].forEach(event =>
                videoRef.current.removeEventListener(event, handleEvent)
            )
        }
    }, [ready, props])

    useEffect(() => {

        if (ready)
            ['loadeddata', 'play', 'pause', 'seeked', 'seeking', 'volumechange', 'ended'].forEach(event =>
                videoRef.current.addEventListener(event, handleEvent)
            )

        console.log("videoRef ", videoRef)
        return () => {
            ['loadeddata', 'play', 'pause', 'seeked', 'seeking', 'volumechange', 'ended'].forEach(event =>
                videoRef.current.removeEventListener(event, handleEvent)
            )
        }
    }, [ready === true])


    function createData() {
        const statementID = "OBFUSCATExxIDxxTOxBExGENERATED"
        console.log("Event Captured :: called createData");
        const statementList = Session.get("displayStatements")
        console.log("Event Captured statement :: ", statementList);
        let templist = [];


        statementList.forEach((statement) => {
            let tempObj = null;
            let actor = props.selectedActor;

            const verb = statement.verb.display["en-US"] || statement.verb.display["en"];
            const objectID = statement.object.id;
            const objectName = statement.object.definition.name["en-US"] || statement.object.definition.name["en"];
            const objectType = statement.object.definition.type;
            const objectDesc = statement.object.definition.description["en-US"] || statement.object.definition.description["en"];
            const activityTypeIRI = statement.context.contextActivities.category[0].id;
            let contextObj = JSON.stringify(statement.context.extensions);

            console.log("Event Captured statementList :: ", statement);
            tempObj = { statementID, actor, verb, objectID, objectName, objectType, objectDesc, activityTypeIRI, contextObj };
            templist.push(tempObj);

        });
        Session.set("ampTableRows", templist);
        return templist;
    }

    const handleEvent = (event) => {

        if (ready) {
            console.log(" handleEvent ", event)
            let myPlayer = event.target;
            let description = myPlayer.localName;
            let didSeek = false;

            let didComplete = false, hasEnded = false;


            let didMute = false;
            let src = event.target.currentSrc;
            let loadedStart = "";
            let loadedSeeked = "";
            let ccEnabled = false;
            let ccLang = "";
            let playedSegments = "";
            let prevPlayedSegments = "";
            let lastPausedSegments = "";
            let playedTime = "",
                pausedTime = "",
                lastPausedTime = "",
                lastPlayedTime = "";
            let time1 = '',
                time2 = '',
                timeTo = "";
            let time = "";
            let seekedTime = "";

            // // use a switch/case to check for each event
            // console.log(" event ", event)
            const video = event.type;

            switch (video) {
                case ('loadeddata'):
                    playedSegments = "0";
                    onStateChange(
                        "start",
                        language,
                        parseFloat(myPlayer.duration).toFixed(3),
                        myPlayer.playbackRate,
                        false,
                        myPlayer.volume,
                        src,
                        description,
                        myPlayer.width,
                        ccEnabled,
                        window.screen.width,
                        window.screen.height,
                        myPlayer.height,
                        ccLang
                    );
                    createData();
                    loadedStart = "start";
                    break;
                case ('play'):
                    
                    playedTime = parseFloat(event.timeStamp).toFixed(3);
                    if (playedTime !== lastPlayedTime) {
                        lastPlayedTime = playedTime;
                        playedSegments += "[,]" + playedTime;
                        if (didSeek === false) {
                            onStateChange(
                                "play",
                                language,
                                0,
                                parseFloat(event.timeStamp).toFixed(3),
                                "NA",
                                "NA",
                                src,
                                description
                            );
                            createData();
                        }
                        didComplete = false;
                    }
                    break;
                case ('pause'):
                    pausedTime = parseFloat(myPlayer.currentTime).toFixed(3);

                    if (pausedTime !== lastPausedTime) {
                        console.log("video event::: ", event);
                        console.log("video pause::: ", didComplete);
                        lastPausedTime = pausedTime;

                        prevPlayedSegments = playedSegments;

                        playedSegments += "[.]" + pausedTime;

                        // setTimeout(() => {
                            if (!myPlayer.seeking) {
                                if(hasEnded){
                                    console.log("video End");
                                }
                                else if (!didComplete) {
                                    lastPausedSegments = playedSegments;
                                    onStateChange(
                                        "pause",
                                        language,
                                        parseFloat(myPlayer.duration).toFixed(3),
                                        parseFloat(myPlayer.currentTime).toFixed(3),
                                        "NA",
                                        "NA",
                                        src,
                                        description,
                                        playedSegments
                                    );
                                    createData();
                                }
                            }
                        // }, 300);
                    }
                    break;

                case ('seeking'):
                    console.log("myPlayer.eventName  ", myPlayer, loadedStart)
                    if (myPlayer.eventName !== "play" || loadedStart === "start") {
                        timeTo = parseFloat(time1).toFixed(3);

                        if (!playedSegments.includes("[,]")) {
                            playedSegments = prevPlayedSegments + "[.]" + timeTo;
                        } else {
                            let currTime = parseFloat(myPlayer.currentTime()).toFixed(3);
                            let lastIndex = lastPausedSegments.lastIndexOf("]");
                            let lastVal = lastPausedSegments.substring(
                                lastIndex + 1,
                                lastPausedSegments.length
                            );

                            if (parseFloat(timeTo) > parseFloat(lastVal)) {
                                playedSegments =
                                    lastPausedSegments + "[,]" + lastVal + "[.]" + timeTo;
                            } else {
                                playedSegments = lastPausedSegments;
                            }
                        }
                    }
                    break;
                case ('seeked'):
                    onStateChange(
                        "seeked",
                        language,
                        parseFloat(myPlayer.duration).toFixed(3),
                        parseFloat(myPlayer.currentTime).toFixed(3),
                        "NA",
                        "NA",
                        src,
                        description,
                        timeTo
                    );
                    createData();
                    didSeek = true;
                    setTimeout(function () {
                        didSeek = false;
                    }, 300);
                    
                    break;
                case ('playing'):
                    playedTime = parseFloat(myPlayer.currentTime).toFixed(3);
                    if (playedTime !== lastPlayedTime) {
                        lastPlayedTime = playedTime;
                        playedSegments += "[,]" + playedTime;
                        if (didSeek === false) {
                            onStateChange(
                                "play",
                                language,
                                0,
                                parseFloat(myPlayer.currentTime).toFixed(3),
                                "NA",
                                "NA",
                                src,
                                description
                            );
                            createData();
                        }
                        didComplete = false;
                    }
                    break;
                case ('error'):
                    onStateChange(
                        "error",
                        language,
                        parseFloat(myPlayer.duration).toFixed(3),
                        parseFloat(myPlayer.currentTime).toFixed(3),
                        "NA",
                        "NA",
                        src,
                        description,
                        playedSegments
                    );
                    createData();
                    break;
                case ('volumechange'):
                    didMute = true;
                    setTimeout(function () {
                        didMute = false;
                    }, 300);

                    onStateChange(
                        "muted",
                        language,
                        parseFloat(myPlayer.duration).toFixed(3),
                        parseFloat(myPlayer.currentTime).toFixed(3),
                        "NA",
                        parseFloat(myPlayer.volume).toFixed(3),
                        src,
                        description
                    );
                    createData();
                    break;
                case ('unmute'):
                    didMute = true;
                    setTimeout(function () {
                        didMute = false;
                    }, 300);

                    onStateChange(
                        "unmute",
                        language,
                        parseFloat(myPlayer.duration()).toFixed(3),
                        parseFloat(myPlayer.currentTime()).toFixed(3),
                        "NA",
                        parseFloat(myPlayer.volume()).toFixed(3),
                        src,
                        description
                    );
                    createData();
                    break;
                case ('ended'):
                    didComplete = true;
                    hasEnded = true;
                    onStateChange(
                        "ended",
                        language,
                        parseFloat(myPlayer.duration).toFixed(3),
                        parseFloat(myPlayer.duration).toFixed(3),
                        "NA",
                        "NA",
                        src,
                        description,
                        playedSegments
                    );
                   
                    createData();
                    break;

                default: 
                
                    onStateChange(
                        "default",
                        language,
                        parseFloat(myPlayer.duration).toFixed(3),
                        parseFloat(myPlayer.currentTime).toFixed(3),
                        "NA",
                        parseFloat(myPlayer.volume).toFixed(3),
                        src,
                        description
                    );
                    createData();
                    break;
                
            }
        }
    }


    const loadedMetaDataHandler = (event) => {
        console.log("event ", event.target)
    }
    return (
        ready ?
            <>
                <video width="650" height="320" controls
                    id="videoDiv" name="video ocean"
                    ref={videoRef}
                    onLoadedMetadata={loadedMetaDataHandler}
                >
                    <source src={oceanVideo} type="video/mp4" />
                </video>

                <div id="player"></div>

            </> : <></>
    );
}
