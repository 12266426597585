import Session from "react-session-api";
import 'react-json-view-lite/dist/index.css';

export const displayStatement = (stmt, verb) => {
  window.ADL.XAPIWrapper.displayStatement(stmt, verb);
};

export const sendStatement = (stmt, verb) => {
  window.ADL.XAPIWrapper.sendStatement(stmt, verb);
};

let library = {};
library.json = {
  replacer: function (match, pIndent, pKey, pVal, pEnd) {
    let key = "<span class=json-key>";
    let val = "<span class=json-value>";
    let str = "<span class=json-string>";
    let r = pIndent || "";
    if (pKey) {
      r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
    }
    if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
    return r + (pEnd || "");
  },
  prettyPrint: function (obj) {
    let jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
    return JSON.stringify(obj, null, 3)
      .replace(/&/g, "&amp;")
      .replace(/\\"/g, "&quot;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(jsonLine, library.json.replacer);
    // .replace(jsonLine, library.json.jsonView(obj));
  },
  jsonView: function (obj) {
    console.log("obj ", typeof obj)
    const json = JSON.stringify(obj);
    let jsonString = JSON.stringify(obj, function (key, value) {
      return (value && typeof value.toJSON === 'function')
        ? value.toJSON()
        : JSON.stringify(value);
    });
    console.log("obj json ", jsonString)
    return `<JsonView data={${jsonString}} shouldExpandNode={collapseAllNested} style={defaultStyles} />`
  },
};

const setData = (data) => {


  console.log("displayStatements set 1 :: ", data["displayStatements"]);
}

(function (ADL) {
  ADL.XAPIWrapper.displayStatement = (stmt, verb) => {
    let entry = document.createElement("article");
    entry.style.marginLeft = "20px";
    entry.innerHTML = library.json.prettyPrint(stmt);
    entry.style.display = "none"
    entry.style.color = "black"
    let title = document.createElement("h3");
    title.style.cursor = "pointer";
    title.style.color = "blue";

    let article = document
      .createElement("verb")
      .appendChild(title)
      .appendChild(document.createTextNode(" + " + verb))
      .parentNode.parentNode.appendChild(entry)
      .appendChild(document.createTextNode("")).parentNode.parentNode;

    title.onclick = function () {
      if (entry.style.display === "none") {
        title.innerText = " - " + verb;
        entry.style.display = "block";
        title.style.color = "black";
      } else {
        title.innerText = " + " + verb;
        entry.style.display = "none";
        title.style.color = "blue";
      }
    };
    document.getElementById("message").prepend(article);
  };

  window.ADL.XAPIWrapper.sendStatement = (stmt, verb) => {
    let actorName = "Test User";
    let dt = new Date();
    stmt.timestamp = dt.toISOString();
    stmt.actor = {
      account: {
        homePage:
          "http://xapi-services-example-app.bint.digitalaviationservices.com",
        name: actorName,
      },
      objectType: "Agent",
    };
   
    displayStatement(stmt, verb);
    let jsonStatements = Session.get("displayStatements");
    jsonStatements.push(stmt)
    Session.set("displayStatements", jsonStatements);
    console.log("displayStatements set ", jsonStatements);

    Session.onSet(setData);

    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        let localStatements = Session.get("statements"); //localStorage.getItem("statements");
        console.log("statements localstorage", localStatements);
        if (localStatements === null || localStatements === "") {
          localStatements = xhr.responseText.replace(/[^0-9A-Z-]+/gi, "");
        } else {
          localStatements =
            localStatements +
            "," +
            xhr.responseText.replace(/[^0-9A-Z-]+/gi, "");
        }
        Session.set("statements", localStatements);
      }
    };
    xhr.open(
      "POST",
      "https://yetanalyticslts-dev.azurewebsites.net/LearningToolService/api/postNoisyStatement"
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader(
      "Access-Control-Allow-Methods",
      "POST,GET,PUT,OPTIONS"
    );
    xhr.send(JSON.stringify(stmt));
  };
})((window.ADL = window.ADL || {}));
