import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Session from "react-session-api";

class CustomTable extends React.Component {

    state = {
        ready: false,
        propsReady: false,
        update: false,
        page: 0,
        rowsPerPage: 5,
        rows: [],
        columns: [],
        showData: false,
        buttonLabel: 'Load Statements'
    }

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            rows: this.props.rows,
            columns: this.props.columns,
            showData: false,
            buttonLabel: 'Load Statements in Table View'
        };

    }

    async componentDidMount() {
        if (!this.state.ready) {
            this.setState((state, props) => ({
                ready: true,
                propsReady: true,
                rows: props.rows
            }));

        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("did update A:: ")
        if (prevState.ready !== this.state.ready) {
            if (this.state.ready) {
                this.setState((state, props) => ({
                    rows: props.rows
                }));

            }
        }
        return null;
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: +event.target.value
        });
    };

    handleTableData = (event) => {
        console.log("load event called :: ", event)
        this.setState({
            rows: Session.get("ampTableRows"),
            showData: true,
            buttonLabel: 'Reload Statements'
        });
    }

    render() {
        return (this.state.ready) ?
            <React.Fragment>

                {/* <Divider sx={{ width: '100%', margin:1 }} aria-hidden="true" /> */}
                <Box
                    width="100%"
                    alignItems="center"
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: '100%',
                            height: 'auto',
                            padding: 3
                        },
                    }}
                >
                    <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}>

                            <Grid item xs={6}>
                                <LoadingButton variant="contained" size="medium" onClick={(e) => this.handleTableData(e)}>{this.state.buttonLabel}</LoadingButton>
                            </Grid>
                        </Grid>
                        <br />
                        {this.state.showData ?
                            <React.Fragment>
                                <TableContainer sx={{ maxHeight: 800 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {this.state.columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rows.length > 0 ? this.state.rows
                                                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover tabIndex={-1} key={row.statementID}>
                                                            {this.state.columns.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number'
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })
                                                : <TableRow />
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={this.state.rows.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </React.Fragment>
                            : <br />}
                    </Paper>
                </Box>
            </React.Fragment>
            : <div></div>;

    }
}

export default CustomTable;